import PropTypes from 'prop-types'

const TimeDividerContainer = ({
  outOfHoursTopHeight = 0,
  outOfHoursBottomHeight = 0,
  calendarType = 'weeklyCalendar',
  practitionerSlots = [],
  defaultAppointmentLength = 15,
  zoom = 'zoomedin',
}) => {
  var myArray = []
  let styleClasses = ''
  let appointmentDefaultHeight =
    calendarType === 'practitionerCalendar'
      ? 140
      : defaultAppointmentLength * 3.5
  if (calendarType === 'practitionerCalendar') {
    styleClasses = 'timeDivider timeDivision--practitioner'
    let length = practitionerSlots.length > 0 ? practitionerSlots.length : 97
    for (let i = 0; i < length - 1; i++) {
      myArray.push(
        <div
          key={i}
          className={styleClasses}
          style={{ height: appointmentDefaultHeight }}
        />,
      )
    }
  } else {
    styleClasses = 'timeDivider'
    let zoomHeight = zoom === 'zoomedin' ? 105 : 52.5
    for (let i = 0; i < 96; i++) {
      myArray.push(
        <div key={i} className={styleClasses} style={{ height: zoomHeight }} />,
      )
    }
  }

  return (
    <div className="timeDivision_container">
      <div
        className="timeDivision_outofhours timeDivision_outofhours--top"
        style={{ height: outOfHoursTopHeight }}
      />
      {myArray}
      <div
        className="timeDivision_outofhours timeDivision_outofhours--bottom"
        style={{ height: outOfHoursBottomHeight }}
      />
    </div>
  )
}

TimeDividerContainer.propTypes = {
  outOfHoursTopHeight: PropTypes.number,
  outOfHoursBottomHeight: PropTypes.number,
  calendarType: PropTypes.string,
  practitionerSlots: PropTypes.array,
  defaultAppointmentLength: PropTypes.number,
  zoom: PropTypes.oneOf(['zoomedin', 'zoomedout']),
}

export default TimeDividerContainer
